import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { InspectionResponseType, inspectionTableDetailsType } from "./inspections-dao.type";
import { InspectionResultsType, InspectionResultsDetailsType, ItemDetailsType } from "./inspections-dao.type";



class inspectionsResponse {
    response : InspectionResponseType
    constructor(inspectionResult : InspectionResultsType){
        const inspectionDetails  = this.generateInspectionDetailsTableData(inspectionResult)
        if(inspectionResult.length>0){
            this.response = {
                inspectionTableHeaders : this.generateInspectionTableHeaders(inspectionDetails),
                inspectionData : this.generateInspectionTableDetails(inspectionDetails),
                error : undefined
            }
        }
        else{
            this.response = {
                inspectionTableHeaders : [],
                inspectionData : [],
                error : "No results were returned for your query."
            }
        }
    }
    generateInspectionDetailsTableData(data : InspectionResultsType) : Array<inspectionTableDetailsType>{
        let inspectionTableDetails : Array<inspectionTableDetailsType> = [];
        (data as InspectionResultsType).forEach((inspectionData: InspectionResultsDetailsType) => {
            const packageData: Array<ItemDetailsType> = inspectionData.packageDetails;
            (packageData as Array<ItemDetailsType>).forEach((packageDetails: ItemDetailsType) => {
                inspectionTableDetails.push({
                        timestamp : {
                            label : "TIMESTAMP",
                            value : this.formatTimeStamp(inspectionData.timestamp)
                        },
                        user : {
                            label : "USER",
                            value : inspectionData.userAlias
                        },
                        package : {
                            label : "PACKAGE",
                            value : inspectionData.packageIds
                        },
                        title : {
                            label : "TITLE",
                            value : packageDetails.title
                        },
                        asin : {
                            label : "ASIN",
                            value : packageDetails.asin
                        },
                        quantity : {
                            label : "QUANTITY",
                            value : packageDetails.quantity
                        }
                    });

            });

            });

        return inspectionTableDetails

    }

    generateInspectionTableHeaders(data : Array<inspectionTableDetailsType>):TableColumns[]{
        let tableHeaders : Array<TableColumns> = []
        if(data.length>0){
            Object.entries(data[0]).map(([key, cellInfo]) => {
                tableHeaders.push({
                    visibleText: cellInfo.label,
                    isColumnActive: true,
                    columnKey: key
                })
            })

        }
        return tableHeaders
    }

    generateInspectionTableDetails(data : Array<inspectionTableDetailsType>): TableData[][]{
        const tableData: Array<Array<TableData>> =[];
        data.map((details)=>{
            let tableRecord: Array<TableData> = []
            Object.entries(details).map(([keys, cellInfo]) => {
                tableRecord.push({
                        value: cellInfo.value,
                        type: cellInfo.type ?? 'text', 
                        width: 300
                    })
            })
            tableData.push(tableRecord)
        })
        return tableData
    }

    formatTimeStamp(timestamp : string){
        const inputDate = timestamp.split('.')[0] + ' UTC'
        return inputDate
    }



   
}
export default inspectionsResponse