import React, { useEffect, useState } from "react";
import { AfnScannableResultsComponentPropsType, getItemsType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { AfnNodeDataType } from "src/common/dao/afn/afn-dao.type";
import { TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { DetailsRendererUtil } from "src/components/helpers/details-renderer-util/details-renderer-util";
import { ToolLinksRenderer } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-utl";
import { TableRendererUtil } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { axiosGet } from "src/common/axios/axios-api-wrapper.hook";
import { getEECSApiForRegion, getRealmFromUrl } from "src/utils/utils";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Theme from "@amzn/meridian/theme";
import "../afn.component.scss"
import { getDownloadDataBtn } from "./afn-table-results.component";

export const AfnLegResultsComponent = ({ afnResults, afnForm, showActionBarComponentsForTable, tableResponseForExport }: AfnScannableResultsComponentPropsType) => {    
    const getTableData = (nodeData: Array<AfnNodeDataType>) => {
        const tableData: Array<Array<TableData>> = [];
        {
            Object.entries(nodeData[0]).map(([key, cellInfo]) => {
                const tableRecord: Array<TableData> = []
                if (cellInfo.isVisible !== false) {
                    tableRecord.push({
                        value: cellInfo.label,
                        type: "header",
                        styles: { fontSize: '12px', wordWrap: 'break-word' }
                    })
                }
                {
                    nodeData.map((currentPackage: AfnNodeDataType) => {
                        Object.entries(currentPackage).map(([keys, cellInfoLocal]) => {
                            if (cellInfoLocal.label === cellInfo.label && cellInfo.isVisible !== false) {
                                tableRecord.push({
                                    value: cellInfoLocal.value ? cellInfoLocal.value : "",
                                    type: cellInfo.type == undefined ? 'text' : cellInfo.type
                                })
                            }
                        })
                    })
                }
                tableData.push(tableRecord)
            })
        }
        return tableData
    }
    const [isLoading, setIsLoading] = useState(false);
    const [loadedIndex, setLoadedIndex] = useState(-1);
    const [loadedItemsDetailsMap, setLoadedItemsDetailsMap] = useState<getItemsType>({ "-1": "" })
    // Clearing the loaded details upon new search 
    useEffect(()=>{
        setLoadedItemsDetailsMap({ "-1": "" })
    },[afnResults])

    // Need to make the scannable id type to be only string
    // This function checks for the item details in the map and during a bulk search will be fetching the
    // details and return it based on the index

    
    const getItemDetailsFromMap = (key: number): string | null => {
        if (loadedItemsDetailsMap.hasOwnProperty(key))
            return loadedItemsDetailsMap[key]
        return null
    }

    const renderMultiLineText = (msg: string): JSX.Element => {
        const lst: string[] = msg.split("<%>");
        return (
          <div className="get_items_data">
            {lst.map((item, index) => (
              <Text type="b100" key={index}>{item}</Text>
            ))}
          </div>
        );
      };

    const getItems = (scannableId: string, index: number) => {
        setIsLoading(true)
        setLoadedIndex(index);
        // TODO: Move it to common util file for calling the Get method
        axiosGet(
            getEECSApiForRegion(),
            `item_details/${scannableId}`,
        ).then((response: any) => {
            setLoadedItemsDetailsMap((loadedItemsDetailsMap) => ({ ...loadedItemsDetailsMap, [index]: response[scannableId].items }))
            setIsLoading(false)
        }).catch((error: any) => {
            setIsLoading(false)
        })
    }

    return (
        <React.Fragment>
            {getDownloadDataBtn(tableResponseForExport)}
            {
                afnResults.map((currentPackage, index) => {
                    if (currentPackage.legInfo.Tid !== undefined && currentPackage.legInfo.Tid !== "recycled") {
                        const newTidUrl = "/?lang=EN&region=" + getRealmFromUrl().toLocaleUpperCase() + "&format=leg&nodeInfo=&shipmentType=scannable&searchId=" + currentPackage.legInfo.Tid;
                        return (
                            <div style={{ "marginTop": "2%" }} >
                                <Box type="outline" spacingInset={"200"} >
                                    <Theme tokens={{ boxBackgroundColorOutline: '#fba3b7' }}>
                                        <Box type="outline" spacingInset="200">
                                            <Text type="h100" alignment="center">
                                                {currentPackage.legInfo.displayString} <a href={newTidUrl} target="_blank">{currentPackage.legInfo.Tid}</a>
                                            </Text>
                                        </Box>
                                    </Theme>
                                </Box>
                            </div>
                        )
                    }

                    return (
                        <div data-cyid="afn-leg-results-comp" style={{ "marginTop": "2%" }}>
                            <Box type="outline" spacingInset={"300"} >
                                    <Theme tokens={{ boxBackgroundColorOutline: currentPackage.legInfo.Tid === "recycled" ? '#8a8de8' : '#77c57f' }}>
                                        <Box type="outline" spacingInset="200">
                                            <Text type="h100" alignment="center">
                                                {currentPackage.legInfo.displayString}
                                            </Text>
                                        </Box>
                                    </Theme>
                                    <Column spacingInset={"200"} spacing={"200"}>
                                        {currentPackage.packageDetails && <DetailsRendererUtil detailsRendererUtilData={currentPackage.packageDetails!} />}
                                        {currentPackage.toolLinks && afnForm.shipmentType != "transshipment" && <ToolLinksRenderer toolLinksDataMap={currentPackage.toolLinks!} />}
                                        { currentPackage?.esmmInfo && currentPackage.enrichedTrackingIdInfo && currentPackage.packageDetails && <Box type="outline" spacingInset={"200"} >
                                            <table>
                                            {afnForm.shipmentType != "transshipment" && <tr>
                                                <td width="10%"><Text type="b100" alignment="left" className="section-header1">{currentPackage?.esmmInfo!.label}</Text></td>
                                                <td><Text type="b100" className="cell-data"> {currentPackage?.esmmInfo!.value}</Text></td>
                                            </tr>}
                                            {currentPackage.enrichedTrackingIdInfo!.isVisible && <tr>
                                                <td width="10%"><Text type="b100" alignment="left" className="section-header1">{currentPackage?.enrichedTrackingIdInfo!.label}</Text></td>
                                                <td><Text type="b100" className="cell-data"> {currentPackage?.enrichedTrackingIdInfo!.value}</Text></td>
                                            </tr>}
                                            <tr>
                                                <td width="10%"><Button size="small" data-cyid="get-items-btn"
                                                        type="primary"
                                                        onClick={() => { getItems(String(currentPackage.packageDetails!.details.label.value), index) }}>{loadedIndex == index && isLoading ?
                                                            <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                                            "Get Items"}
                                                    </Button>
                                                </td>
                                                <td><Text type="b100" className="cell-data">{ renderMultiLineText ( getItemDetailsFromMap(index) || "")}</Text></td>
                                            </tr>
                                            </table>
                                        </Box>}
                                        <Box type="outline">
                                            <div className="table-render">
                                            <TableRendererUtil
                                                tableData={getTableData(currentPackage.nodeDetails)}
                                                onFiltersClick={() => { }}
                                                tableHeaders={[]}
                                                showActionBarComponents={showActionBarComponentsForTable}
                                                numberOfPages={-1}
                                                onTablePageChange={() => { }}
                                                isRowHeader={true}
                                            />
                                            </div>
                                        </Box>
                                    </Column>
                            </Box>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}