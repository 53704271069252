class preSlamSearchRequest {
    shipmentId: string;
    FC: string;

    constructor(shipmentId: string, FC: string){
        this.shipmentId = shipmentId;
        this.FC = FC;
    }
}

export default preSlamSearchRequest;