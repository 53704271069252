import {axiosGet, axiosPost} from "src/common/axios/axios-api-wrapper.hook";
import afnSearchRequest from "src/common/dao/afn/afn-request.dao";
import afnSearchResponse from "src/common/dao/afn/afn-response.dao";
import {afnInputFieldsType, stackingFilterInputType} from "src/pages/visibility-page/components/afn/afn.component.type";
import {getEECSApiForRegion} from "src/utils/utils";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { katalLoggerInfo } from "src/logger";

export const afnVisibilityApi = async (afnForm: afnInputFieldsType) => {
    const afnSearchQueryParams = new afnSearchRequest(afnForm);
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "AfnVisibilityApi => Request", afnSearchQueryParams)
    return axiosPost(
        getEECSApiForRegion(),
        afnForm.shipmentType,
        afnSearchQueryParams.request
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "AfnVisibilityApi => Response", response)
        latencyMetricsPublisher.publishTimerMonitor( 'afn', requestTime)
        return new afnSearchResponse(afnForm, response);
    });
};
export const doAfnBulkSearchRequestApiCall = async (bulkSearchRequest:any) => {
    return axiosPost(
        getEECSApiForRegion(),
        "handle-bulk-search-request",
        bulkSearchRequest
    ).then((response: any) => {
        return response;
    });
};

export const stackingFilterApi = async (sfForm: stackingFilterInputType) => {
    return axiosGet(
        getEECSApiForRegion(),
        `stackingFilter/${sfForm.nodeId}/${sfForm.stackingFilter}`,
    ).then((response: any) => {
        katalLoggerInfo(window.location.href, "stackingFilterApi => Response", response)
        return response;
    }).catch((error: any)=>{
        return error
    });
};

export const manifestDetailsApi = async (shipmentId: String) => {
    return axiosGet(
        getEECSApiForRegion(),
        `manifestDetails/${shipmentId}`,
    ).then((response: any) => {
        katalLoggerInfo(window.location.href, "manifestDetailsApi => Response", response)
        return response;
    }).catch((error: any)=>{
        return error
    });
};

export const userFeedbackSubmitApi = async (userFeedback: any) => {
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "User Feedback submitted for going back to V1 => Response", userFeedback)
    return axiosPost(
        getEECSApiForRegion(),
        `create_sim`,
        userFeedback
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "User Feedback submitted for going back to V1 => Response", response)
        latencyMetricsPublisher.publishTimerMonitor( 'user-feedback', requestTime)
        return response;
    });
};
