import { activityReportRequestType } from "./activityreport-dao.type";
import { activityReportInputFieldsType, searchType } from "../../../pages/visibility-page/components/activityreport/activityreport.component.type"


class activityReportRequest {
    request : activityReportRequestType
    constructor(searchType: searchType,searchId: string, fromTimeStamp: number, toTimeStamp: number){
        let user:string = "";
        let location : string = "";
        if(searchType === "userId")
            user = searchId.toLowerCase()
        else
            location = searchId.toUpperCase()
        this.request = {
            userId : user,
            location : location,
            startTime : fromTimeStamp,
            endTime : toTimeStamp
        }
    }
    
}

export default activityReportRequest