import { FACET_FIELDS_BY_DOCTYPE_MAP } from "src/common/constants/ofis.const";
import { facetFilterType, ofisPayloadType, ofisRequestType } from "./ofis-dao.type";

class ofisRequest {
	ofisPayload: ofisPayloadType
	request: ofisRequestType

	constructor(ofisPayload: ofisPayloadType) {
		this.ofisPayload = ofisPayload
		this.request = this.buildOfisRequest();
	}

	buildOfisRequest() : ofisRequestType {
		var ofisRequest: ofisRequestType = {
			inputId: this.ofisPayload.searchId.split(","),
			type: this.ofisPayload.searchType,
			docType: this.ofisPayload.documentType,
			size: this.ofisPayload.size || 10,
			offset: this.ofisPayload.offset || 0,
			facetConstraints: this.ofisPayload.facetFilters,
		}
		return ofisRequest;
	}
}

export default ofisRequest