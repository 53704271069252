import React, {useEffect, memo, useState, useRef} from "react"
import Masthead, {
    MastheadMenuButton,
    MastheadTitle,
} from "@amzn/meridian/masthead"
import {AppHeaderComponentPropsType} from "src/components/presentational/app-header/app-header.component.type";
import {MenuUtilComponent} from "src/components/helpers/menu-util/menu-util";
import { languageMenu, regionMenu } from "src/common/constants/header.const";
import "./app-header.component.scss";
import Theme from "@amzn/meridian/theme"
import { TokenObject } from "src/components/helpers/button-util/button-util.type"
import { useSearchParams } from "react-router-dom";
import { STR_LANGUAGE, STR_REGION } from "src/common/constants/utils.const";
import { filterAndUpdateUrl } from "src/utils/utils";
import {MenuDataType} from "src/components/helpers/menu-util/menu-util.type"
import logo from 'src/images/eagleeye_gradient.png';
import { UserOptions } from "./user-options";
import { TOKEN_KEY } from "src/auth/cognito-auth";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";

const AppHeaderComponent = ({handleMenuButton, isMenuOpen}: AppHeaderComponentPropsType) => {
    let lang = localStorage.getItem("language");
    let reg = localStorage.getItem("region");

    const [language,setLanguage] = useState("");
    const [region,setRegion] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const prevRegionRef = useRef(region);
    const [openModal, setOpenModal] = useState(false);
    const onModalClose = () => setOpenModal(false);
    const onClickFooterButton = () => {
        setOpenModal(false)
    };

    let validateInput = (providedValue:string ,dataStore:MenuDataType)=>{
        let exists = dataStore.Items.some(item => item.visibleText === providedValue);
        return exists ? providedValue : dataStore.Items[0].visibleText
    }


    useEffect(() => {
        let regionParams = searchParams.get(STR_REGION) || reg || "NA"
        let languageParams = searchParams.get(STR_LANGUAGE) || lang || "EN"
        let redirectedFromV1 = searchParams.get("redirect")
        if(redirectedFromV1 === 'true'){
            setOpenModal(true)
            setSearchParams({redirect: "false"});
        }
        setRegion(validateInput(regionParams,regionMenu))
        setLanguage( validateInput(languageParams,languageMenu))
    }, [])

    useEffect(() => {
        var params = {
            [STR_LANGUAGE]: language,
            [STR_REGION]: region
        }
        filterAndUpdateUrl(window.location.href, params, document.title)
    }, [language, region])

    const customTokens:TokenObject = {
        mastheadBackgroundColor: "#045C69", // teal-600
        buttonForegroundColorIconLightBackdropDefault:"#fff"
    }

    useEffect(() => {
        // When region change happens from CN to non CN or vice versa , reset auth token and reload page
        if ((region!="" && prevRegionRef.current != "" ) &&
            region != prevRegionRef.current && (prevRegionRef.current =="CN" || region =="CN") )
        {
            localStorage.removeItem(TOKEN_KEY)
            window.location.reload();
        }
        prevRegionRef.current = region;
    }, [region]);


    return (
        <>
            <Theme  tokens={customTokens}>
                <Masthead size="medium">
                    <MastheadMenuButton onClick={() => {
                        handleMenuButton(!isMenuOpen)
                    }} open={true}/>
                    <MastheadTitle href="/search">
                        <img src={logo} alt="Eagle Eye" className="logo" />
                    </MastheadTitle>
                    <MenuUtilComponent menuData={languageMenu} value={language} setValue={setLanguage}></MenuUtilComponent>
                    <MenuUtilComponent menuData={regionMenu} value={region} setValue={setRegion}></MenuUtilComponent>
                    <UserOptions />
                </Masthead>
            </Theme>
            <Modal
                title="Welcome To EagleEye 2.0!!"
                open={openModal}
                onClose={onModalClose}
                scrollContainer="viewport"
                closeLabel="Close"
                bodySpacingInset="400"
            >
                <Text id="modal-description" tag="div">
                    <Text>
                        <b>Important Note!</b> The new UI complies with Amazon’s information security standards. <br/>Information is classified, Users are guided to request the required permissions by completing the three steps in the&nbsp;
                        <a href="https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions/#HWhatisthePermissionModel3F" target="_blank">Permissions Model Wiki</a> to be able to see the information.
                        <br/>
                        <br/>
                        Not having the required permissions would show some of the fields as <b>‘Restricted’.</b> <br/> <br/>
                        Here are the key changes, you should be aware of:<br/>
                        1. Users need to be part of a permissions group (as mentioned above) to use EagleEye 2.0. <br/>
                        2. The tool link (web URL) is changed, bookmark the new ones. <br/>
                        3. Users should select the region (available on top right) before performing a search operation. <br/>
                        4. Manifest details is moved from the table to the links section as ‘Manifest Visibility’. <br/>
                        <br/>
                        User Guide along with a quick (2 minute) video available <a href="https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/EagleEye_UserNavigationGuide#HShortvideoguideofuserexperience282minutes29:" target="_blank"> Here </a>
                    </Text>
                </Text>
                <></>
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="primary" size="small" onClick={onClickFooterButton}>
                            Continue
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default memo(AppHeaderComponent)