import { stuckShipmenRequestType, stuckShipmentPayloadType } from "./stuck-shipments-dao.type";
import { useAuthContext } from "src/context/AuthenticationContext";
import {getEECSApiForRegion} from "src/utils/utils";



class stuckShipmentRequest {
	stuckShipmentPayload: stuckShipmentPayloadType
	request: stuckShipmenRequestType
	
   
	constructor(stuckShipmentPayload: stuckShipmentPayloadType) {
		this.stuckShipmentPayload = stuckShipmentPayload
		this.request = this.buildstuckShipmentRequest();
	}
	

	buildstuckShipmentRequest() : stuckShipmenRequestType {
	
		let region = getEECSApiForRegion
		var stuckShipmentRequest: stuckShipmenRequestType = {
			nodeId: this.stuckShipmentPayload.nodeId ?  this.stuckShipmentPayload.nodeId : "",
            shipmentId: this.stuckShipmentPayload.shipmentId ?  this.stuckShipmentPayload.shipmentId : "",
            isBulkClear: this.stuckShipmentPayload.isBulkClear,
            requesterName: this.stuckShipmentPayload.requesterName ? this.stuckShipmentPayload.requesterName : "",
            requestId: this.stuckShipmentPayload.requestId ? this.stuckShipmentPayload.requestId : "",
            fileContentEncoded: this.stuckShipmentPayload.fileContentEncoded ,
            clearShipmentsStage: this.stuckShipmentPayload.clearShipmentsStage,
            clearShipmentsRegion: this.stuckShipmentPayload.clearShipmentsRegion ? this.stuckShipmentPayload.clearShipmentsRegion : "",
		}
		return stuckShipmentRequest;
	}
}

export default stuckShipmentRequest