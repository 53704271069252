import Alert from "@amzn/meridian/alert";
import { AlertType } from "@amzn/meridian/alert/alert";
import Button from "@amzn/meridian/button";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import React from "react";
import { useState, useRef, useEffect } from "react";
import { axiosPost } from "src/common/axios/axios-api-wrapper.hook";
import { useAuthContext } from "src/context/AuthenticationContext";
import { getEECSAPIEndpointForRegion, getValidRegion } from "src/utils/utils";

export const UserOptions = () => {
    var alias: string = useAuthContext().userAlias
    const getImageUrl = () => `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${alias}&type=badge&dummy.png`;
    const REFRESH_PERMISSIONS = 'Refresh Permissions'
    const [refreshPermissionsBtnTxt, setrefreshPermissionsBtnTxt] = useState(REFRESH_PERMISSIONS)
    const buttonRef = useRef()
    const [open, setOpen] = useState(false)
    const onClickButton = () => setOpen(true)
    const [toastConfig, settoastConfig] = useState({
        message: "",
        type: "success" as AlertType
    })

    useEffect(() => {
        // incase user doesnt closes toast , audo close it after 5 seconds
        if (toastConfig.message.length >= 1) {
            const timeout = setTimeout(() => {
                settoastConfig({
                    message: "",
                    type: toastConfig.type
                });
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [toastConfig.message]);
    
    const refreshPermissionsInRegion = async (region:string) => {
        try {
            const response = await axiosPost(
                getEECSAPIEndpointForRegion(region),
                "delete-cache",
                { "key": `approvedResources<%>${alias}` },
                { timeout: 3000 }
            );
            setTimeout(() => {
                settoastConfig({
                    message: "Successfully refreshed permissions in region: " + region,
                    type: "success" as AlertType
                });
            }, 1000);
        } catch (err) {
            setTimeout(() => {
                settoastConfig({
                    message: "Error while refreshing permissions in region:  " + region,
                    type: "error" as AlertType
                });
            }, 1000);
        }
      };

    const handleRefreshCacheClick = async () =>{
        let regions = ["NA", "EU", "FE"];
         if (getValidRegion() === "CN" ){
            regions =["CN"]
         }
        for (const region of regions) {
            await refreshPermissionsInRegion(region);
        }
    }

    return (
        <React.Fragment>
            <Button
                onMouseEnter={onClickButton}
                ref={buttonRef}
                onClick={() => {
                    window.open(`https://phonetool.amazon.com/users/${alias}`, "_blank");
                }}
                type="link"
                size="large"
                aria-expanded="false"
            >
                <div className="user-section">
                    Welcome, {alias}
                    <img src={getImageUrl()} alt="..." className="user-profile-icon" />
                </div>
            </Button>

            <Menu
                anchorNode={buttonRef.current}
                id="my-menu"
                open={open}
                position="bottom"
                onClose={() => { setOpen(false) }}
            >
                <MenuItem
                    onClick={async () => {
                        handleRefreshCacheClick()
                    }}>
                    <div
                        style={{ fontSize: '14px', whiteSpace: 'nowrap', }}
                    >
                        {refreshPermissionsBtnTxt}
                    </div>
                </MenuItem>
            </Menu>
            {toastConfig.message.length >= 1 && <div style={{ position: 'fixed', bottom: '10px', left: '10px' }} >
                <Alert
                    onClose={() => {
                        settoastConfig({
                            message: "",
                            type: "error" as AlertType
                        })
                    }}
                    type={toastConfig.type}
                    size="medium"
                >{toastConfig.message}</Alert>
            </div>
            }
        </React.Fragment>
    )
}