


import React, { useState, useEffect, memo } from "react"
import { useNavigate } from "react-router-dom"
import { NavTabComponentProps, tab } from "src/components/helpers/navtab-util/navTab.util.type";
import { VisibilityNavTabItems } from "src/common/constants/visiblity-navTab.const";
import {NavTabComponent} from "src/components/helpers/navtab-util/navTab.util"


const VisibilityNavTabComponent = ({ tab }: tab) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("");
  useEffect(() => {
    setCurrentTab(tab);
  })

  return (
    <NavTabComponent tab={tab} NavTabItems={VisibilityNavTabItems} />
  )
}
export default memo(VisibilityNavTabComponent);

