import { getDataForRegion } from "src/common/constants/translations.const";
import {infoDataType} from "src/common/dao/mfn/mfn-dao.type";
import {TableColumns, TableData} from "src/components/helpers/table-renderer-util/table-renderer-util.type";

export const generateTableHeaders = (data: Array<Record<string, infoDataType>>): TableColumns[] => {
    let tableHeaders: Array<TableColumns> = [];
    Object.entries(data[0]).map(([key, cellInfo]) => {
            tableHeaders.push({
                visibleText: cellInfo.label,
                isColumnActive: true,
                columnKey: key,
                width: cellInfo.width ?? '',
                styles: cellInfo.styles
            })
    })
    return tableHeaders;
}

export const generateTableData = (data: Array<Record<string, infoDataType>>): TableData[][] => {
    let tableData: Array<Array<TableData>> = [];
    data.map((currentPackage) => {
        let tableRecord: Array<TableData> = []
        Object.entries(currentPackage).map(([keys, cellInfo]) => {
                tableRecord.push({
                    value: cellInfo.value ?? "",
                    type: cellInfo.type == undefined ? 'text' : cellInfo.type,
                    addCopyIcon: cellInfo.value != null && cellInfo.value != '---' && 
                        cellInfo.value.toString().trim().length !== 0 && (cellInfo.addCopyIcon !== undefined ? 
                        cellInfo.addCopyIcon : false),
                    styles: cellInfo.styles
                })
        })
        tableData.push(tableRecord)
    })
    return tableData;
}

export const getInnerHtmlText = (htmlString: string): string => {
    var patternToMatchHtmlTag = /<\/?[a-z][\s\S]*>/
    var patterToMatchInnerHtml = />[a-z0-9]+<\//
    if (patternToMatchHtmlTag.test(htmlString)) {
        var match = htmlString.match(patterToMatchInnerHtml)
        if (match) { return match[0].replace(">","").replace("</","") }
        return htmlString
    } else { return htmlString }
}

export const copyToClipboardUtil = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy)
}

export const translateStrings = (req : any):any => {
    let d1: any = {}
    Object.keys(req).forEach((key)=>{
        const value = req[key]
        const translatedText = getDataForRegion(value.label)
        const modifiedValue = { ...value,label:translatedText};
        d1[key]=modifiedValue
    });
    return d1

}

export const translateStringsForRecord = (req : any): any => {
    const translatedText = getDataForRegion(req.label)
    const result = { ...req,label: translatedText}
    return result;

}

export const formatTime = (time: string | undefined): string => {
    time = time ? time : "---";
    let [datePart, timePart] = time.split(' ');
    timePart = timePart ? timePart : "";
    let [day, month, year] = datePart.split('-');
    let [hours, minutes, seconds] = timePart.split(':');

    // Check if the provided values are valid
    if (isNaN(parseInt(day)) || isNaN(parseInt(month)) || isNaN(parseInt(year)) || isNaN(parseInt(hours)) || isNaN(parseInt(minutes)) || isNaN(parseInt(seconds))) {
        return time;
    }
    // We are getting the date in DD-MM-YYYY format and the default type is MM-DD-YYYY when we are trying to parse it
    // if the date is 14-08-2023 it returns it as in invalid date hence changing the order and then parsing the date.

    const dateObject = new Date(
        parseInt(year),
        parseInt(month) - 1, // Months are 0-indexed in JavaScript (0 = January)
        parseInt(day),
        parseInt(hours),
        parseInt(minutes),
        parseInt(seconds)
    );
    if (dateObject.toString() === "Invalid Date") {
        return time;
    }

    // Commenting the logic for displaying the Day, If we want to show it in future we can uncomment it
    // const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // const dayOfWeek = daysOfWeek[dateObject.getUTCDay()];
    const formattedMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(dateObject);
    // const formattedDate = `${dayOfWeek} ${dateObject.getUTCDate()}-${formattedMonth}-${dateObject.getUTCFullYear()} ${dateObject.getUTCHours()}:${dateObject.getUTCMinutes()}:${dateObject.getUTCSeconds()} UTC`;
    const formattedDate = `${day}-${formattedMonth}-${year} ${hours}:${minutes}:${seconds} UTC`;
    return formattedDate;
}