import React, { memo } from "react"
import {Route, Routes } from "react-router-dom"
import AfnComponent from "src/pages/visibility-page/components/afn/afn.component";
import MfnComponent from "src/pages/visibility-page/components/mfn/mfn.component";
import OfisComponent from "src/pages/visibility-page/components/ofis/ofis.component";
import PreSlamComponent from "src/pages/visibility-page/components/preslam/pre-slam.component";
import XorRedriveComponent from "src/pages/solutions-page/components/xor-redrive/xor-redrive.component";
import StuckShipmentsComponent from "src/pages/solutions-page/components/stuck-shipments/stuck-shipments.component";
import InspectionComponent from 'src/pages/visibility-page/components/inspections/inspections.component';
import ActivityReportComponent from 'src/pages/visibility-page/components/activityreport/activityreport.component';

const AppContentComponent = () => {

    return (
        <Routes>
            <Route path="/search" element={<AfnComponent/>} />
            <Route path="/mfn" element={<MfnComponent />} />
            <Route path="/ofis" element={<OfisComponent />} />
            <Route path="/preslam" element={<PreSlamComponent />} />
            <Route path="/xor-redrive" element={<XorRedriveComponent/>} />
            <Route path="/stuck-shipments" element={<StuckShipmentsComponent />} />
            <Route path="/inspections" element={<InspectionComponent />} />
            <Route path="/activity-report" element={<ActivityReportComponent />} />
            <Route path="/" element={<AfnComponent/>} />
        </Routes>
    )
}
export default memo(AppContentComponent)